import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LandingPage"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegistrationView"),
  },
  {
    path: "/payment/success",
    name: "SuccessPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/SuccessPayment"),
  },
  {
    path: "/refund-policy",
    name: "RefundPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/RefundPolicy"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/Policy/TermsAndConditions"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/privacy-policy"),
  },
  {
    path: "/terms-of-purchase",
    name: "terms-of-purchase",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/TermsofPurchase"),
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/MembershipView"),
  },
  {
    path: "/membership-cancellation",
    name: "membership",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/Policy/MembershipCancellation"
      ),
  },
  {
    path: "/atomic-body-at-home",
    name: "atomic-body-at-home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/AtomicBodyAtHome"),
  },
  {
    path: "/a-diet-you-wont-quit",
    name: "a_diet_you_wont_quit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/ADietYouWontQuit"),
  },
  {
    path: "/breathing-is-life",
    name: "breathing-is-life",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/BreathingIsLife"),
  },
  {
    path: "/healthy-and-toned-back",
    name: "healthy_and_toned_back",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/books/HealthyAndTonedBack"
      ),
  },
  {
    path: "/keto-diet",
    name: "keto-diet",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/books/KetoDietMasterclass"
      ),
  },
  {
    path: "/lose-weight",
    name: "lose-weight",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/LoseWeight"),
  },
  {
    path: "/app",
    component: () => import(/* webpackChunkName: "about" */ "../views/AppView"),
    children: [
      {
        path: "/",
        name: "WorkoutBuilder",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/DietsWrp"),
      },
      {
        path: "/guides",
        name: "FitnessGuides",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/FitnessGuides"),
      },
      {
        path: "/profile",
        name: "AccountSettings",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/AccountSettings"),
        children: [
          {
            path: "",
            name: "personalInfo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/Settings/PersonalInformation"
              ),
          },
          {
            path: "/subscription",
            name: "subscription",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/Settings/SubscriptionsPage"
              ),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (to.path == "/refund-policy") {
    next();
    return;
  }
  if (to.path == "/subscription-terms-ios") {
    next();
    return;
  }
  if (to.path == "/terms-and-conditions") {
    next();
    return;
  }
  if (to.path == "/privacy-policy") {
    next();
    return;
  }
  if (to.path == "/membership-cancellation") {
    next();
    return;
  }
  if (to.path == "/terms-of-purchase") {
    next();
    return;
  }
  if (to.path == "/membership") {
    next();
    return;
  }
  if (!user && to.path == "/payment/success") {
    next();
    return;
  }
  if (
    !user &&
    (to.path === "/login" || to.path === "/registration" || to.path === "/")
  ) {
    next();
    return;
  }

  if (
    (to.path === "/login" || to.path === "/registration" || to.path === "/") &&
    user
  ) {
    next("/app");
    return;
  }

  next();
});

export default router;
