import { service_url } from "@/api/config";
import Http from "../api/http";

export function login(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/login`,
    method: "post",
    data: { ...data },
  });
}
export function auth(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth`,
    method: "post",
    data: data,
  });
}
export function register(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/register`,
    method: "post",
    data: data,
  });
}
export function sendCode(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/code`,
    method: "post",
    data: data,
  });
}

export function logout() {
  return Http._axios({
    url: `${service_url}/api/v1/auth/logout`,
    method: "post",
  });
}

export function getProfile() {
  return Http._axios({
    url: `${service_url}/api/v1/users/me`,
    method: "get",
  });
}

export function updateUserInfo(data) {
  return Http._axios({
    url: `${service_url}/api/v1/users/me`,
    method: "patch",
    data: data,
  });
}


